import React from 'react'
import styled from '@emotion/styled'
import { breakPoints } from '../styling'

const StyledSection = styled('section')({
    '& h1': {
      borderBottom: '1px solid #DEDEDE',
      color: '#646464',
      fontSize: '20px',
      padding: '0 0 10px 0',
    },
    '& h2': {
      color: '#DF6767',
      fontSize: '28px',
      marginBottom: '5px',
      marginTop: '40px',
    },
    '& h3': {
      color: '#5A5A5A',
      display: 'inline',
      marginTop: '5px',
      fontSize: '16px',
    },
    '& .listItems': {
      '& li': {
        display: 'inline',
        listStyle: 'none',
        marginRight: '10px',
      },
      color: '#5A5A5A',
      display: 'inline',
      [breakPoints.phone]: {
        display: 'block',
        paddingLeft: '0',
      },
    },
    '& p': {
      margin: '40px 0',
    },
    color: '#FAFAFA',
    margin: '0',
    width: '100%',
})

const Block = ({ children, id, title }) => (
  <StyledSection>
    <h1 id={id}>{title}</h1>
    {children}
  </StyledSection>
)

export default Block


