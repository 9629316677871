import React from 'react'
import { Link } from "gatsby"
import styled from '@emotion/styled'
import Layout from '../components/layout'
import Block from '../components/block'

const StyledSection = styled('section')({
    
});

const ResumePage = () => {

    return (
        <Layout>
            <Block>
                <StyledSection>
                    <h1>Resume</h1>
                    <p>Coming Soon</p>
                    <p><a href="https://www.linkedin.com/in/ryanballa/">Linked In</a></p>
                </StyledSection>
            </Block>
        </Layout>
    )   
}

export default ResumePage