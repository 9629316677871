/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'

import Header from "./header"
import './normalize.css'
import "./site.css"

const StyledSection = styled('section')({
  color: '#b1b1b1',
  margin: '0 60px',
  maxWidth: '10000px',
});

const StyledFooter = styled('footer')({
  borderTop: '1px solid #DEDEDE',
  color: '#ccc',
  fontSize: '10px',
  padding: '20px 20px 20px 60px',
})

const Layout = ({ children, noNav }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header noNav={noNav} siteTitle={data.site.siteMetadata.title} />
        <StyledSection>
          <main>{children}</main>
        </StyledSection>
        <StyledFooter>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
        </StyledFooter>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
