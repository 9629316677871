import PropTypes from "prop-types"
import React from "react"
import styled from '@emotion/styled'
import { Link } from 'react-scroll';

const StyledHeader = styled('header')({
  '& h1': {
    '& a': {
      color: '#DF6767',
      textDecoration: 'none',
    },
    fontFamily: 'Lobster',
    margin: '20px 0 20px 60px',
  },
  '& nav': {
    '& a': {
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
      '&.active': {
        color: '#DF6767',
        textDecoration: 'none',
      },
      color: '#DF6767',
      textDecoration: 'none'
    },
    fontSize: '16px',
  },
  '& ol': {
    '& li': {
      listStyle: 'none',
      marginRight: '25px',
    },
    display: 'flex',
    margin: '20px 60px 20px 0',
  },
  alignItems: 'center',
  borderBottom: '1px solid #DEDEDE',
  display: 'flex',
  justifyContent: 'space-between',
});

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <h1>
      <a
        href="/"
      >
        <span>{siteTitle}</span>
      </a>
    </h1>
    <nav>
      <ol>
        <li>
          <Link to="about" spy={true} smooth={true} duration={500}>About</Link>
        </li>
        <li>
          <Link to="work" spy={true} smooth={true} duration={500}>Work</Link>
        </li>
        {/* <li>
          <Link activeClassName="active" to="/resume">Resume</Link>
        </li> */}
        <li>
          <Link to="contact" spy={true} smooth={true} duration={500}>Contact</Link>
        </li>
      </ol>
    </nav>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
